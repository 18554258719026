import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { message, Row, Spin } from 'antd';
import { GrammarColumn, ListeningColumn, ReadingColumn } from './Columns';

const UnansweredQuestions = () => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [answer, setAnswer] = useState({});

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const { data } = await axios.get('https://api.weekendteam.online/get_all_unanswered_records');
        setQuestions(data.records);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching questions", error);
      }
    };

    fetchQuestions();
    const interval = setInterval(fetchQuestions, 2000);
    return () => clearInterval(interval);
  }, []);

  const handleAnswerChange = (id, answerText) => {
    setAnswer(prev => ({ ...prev, [id]: answerText }));
  };

  const handleOptionSelect = (id, option) => {
    setAnswer(prev => ({ ...prev, [id]: option }));
  };

  const handleSubmitAnswer = async (id) => {
    if (!answer[id]) {
      message.warning("Vui lòng nhập câu trả lời.");
      return;
    }

    try {
      await axios.post('https://api.weekendteam.online/answer_unanswered_record', {
        _id: id,
        answer: answer[id],
      });
      setAnswer(prev => {
        const newAnswer = { ...prev };
        delete newAnswer[id];
        return newAnswer;
      });
      message.success("Trả lời thành công!");
    } catch (error) {
      console.error("Error submitting answer", error);
      message.error("Đã xảy ra lỗi khi trả lời câu hỏi.");
    }
  };

  if (loading) return <Spin size="large" tip="Đang tải dữ liệu..." />;

  return (
      <div className="questions-container">
        <Row gutter={[16, 16]}>
          <GrammarColumn
              questions={questions.filter(q => q.type === 'Grammar')}
              answer={answer}
              onAnswerChange={handleAnswerChange}
              onOptionSelect={handleOptionSelect}
              onSubmitAnswer={handleSubmitAnswer}
          />
          <ListeningColumn
              questions={questions.filter(q => q.type === 'Listening')}
              answer={answer}
              onAnswerChange={handleAnswerChange}
              onOptionSelect={handleOptionSelect}
              onSubmitAnswer={handleSubmitAnswer}
          />
          <ReadingColumn
              questions={questions.filter(q => q.type === 'Reading')}
              answer={answer}
              onAnswerChange={handleAnswerChange}
              onOptionSelect={handleOptionSelect}
              onSubmitAnswer={handleSubmitAnswer}
          />
        </Row>
      </div>
  );
};

export default UnansweredQuestions;
