import React from 'react';
import { Card, Button, Input, message } from 'antd';
import StickyHeader from './StickyHeader';
import Options from './Options';

const QuestionCard = ({ question, answer, onAnswerChange, onOptionSelect, onSubmitAnswer }) => (
    <Card
        title={<StickyHeader question={question} />}
        bordered={false}
        style={{
            height: 'auto',
            maxHeight: '400px',
            overflowY: 'auto',
        }}
    >
        <p><strong>Dạng bài thi:</strong> {question.type}</p>
        <p><strong>Ngôn ngữ:</strong> {question.language}</p>
        {question.type === 'Grammar' && (
            <p><strong>Exam:</strong> {question.exam}</p>
        )}
        {question.type === 'Listening' && (
            <p><strong>Exam:</strong> Giá trị dài, không phải hiển thị</p>
        )}
        {question.type === 'Reading' && (
            <p><strong>Exam:</strong> {question.exam}</p>
        )}

        {question.dang_bai === 'multiple-choice' && (
            <Options
                options={question.options}
                selectedOption={answer[question._id]}
                onOptionSelect={(option) => onOptionSelect(question._id, option)}
            />
        )}

        <Input.TextArea
            value={answer[question._id] || ''}
            onChange={(e) => onAnswerChange(question._id, e.target.value)}
            placeholder="Nhập câu trả lời"
            rows={4}
            style={{ marginBottom: 16 }}
        />

        <Button type="primary" onClick={() => onSubmitAnswer(question._id)} block>
            Trả lời
        </Button>
    </Card>
);

export default QuestionCard;
