import React from 'react';
import { Col } from 'antd';
import QuestionCard from './QuestionCard';

export const GrammarColumn = ({ questions, answer, onAnswerChange, onOptionSelect, onSubmitAnswer }) => (
    <Col span={8}>
        <h2>Grammar</h2>
        {questions.map(question => (
            <QuestionCard
                key={question._id}
                question={question}
                answer={answer}
                onAnswerChange={onAnswerChange}
                onOptionSelect={onOptionSelect}
                onSubmitAnswer={onSubmitAnswer}
            />
        ))}
    </Col>
);

export const ListeningColumn = ({ questions, answer, onAnswerChange, onOptionSelect, onSubmitAnswer }) => (
    <Col span={8}>
        <h2>Listening</h2>
        {questions.map(question => (
            <QuestionCard
                key={question._id}
                question={question}
                answer={answer}
                onAnswerChange={onAnswerChange}
                onOptionSelect={onOptionSelect}
                onSubmitAnswer={onSubmitAnswer}
            />
        ))}
    </Col>
);

export const ReadingColumn = ({ questions, answer, onAnswerChange, onOptionSelect, onSubmitAnswer }) => (
    <Col span={8}>
        <h2>Reading</h2>
        {questions.map(question => (
            <QuestionCard
                key={question._id}
                question={question}
                answer={answer}
                onAnswerChange={onAnswerChange}
                onOptionSelect={onOptionSelect}
                onSubmitAnswer={onSubmitAnswer}
            />
        ))}
    </Col>
);
