import React from 'react';
import { Button } from 'antd';

const Options = ({ options, selectedOption, onOptionSelect }) => (
    <div>
        <strong>Lựa chọn:</strong>
        {options.map((option, index) => (
            <Button
                key={index}
                type={selectedOption === option ? "primary" : "default"}
                style={{ margin: '4px' }}
                onClick={() => onOptionSelect(option)}
            >
                {option}
            </Button>
        ))}
    </div>
);

export default Options;
