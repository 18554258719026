import React, { useState } from 'react';
import { Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const convertQuestionToStr = (question) => {
    let questionJson = {};
    let convertToStr = false;
    const type = question.type;
    switch (type) {
        case "Grammar":
            questionJson = {
                "question": question.question,
                "exam": question.exam,
                "answers": question.options
            };
            convertToStr = true;
            break;
        case "Listening":
            questionJson = {
                "srcValue": question.exam,
                "labels": question.options
            };
            convertToStr = true;
            break;
        case "Reading":
            questionJson = `${question.exam}\n`;
            question.options.forEach(element => {
                questionJson += `\n- ${element}`;
            });
            convertToStr = false;
            break;
        default:
            break;
    }

    let questionJsonStr = "";
    if (convertToStr) {
        questionJsonStr = JSON.stringify(questionJson, null, 2);
    } else {
        questionJsonStr = questionJson;
    }
    return questionJsonStr;
}

const StickyHeader = ({ question }) => {
    const [copySuccess, setCopySuccess] = useState(false);

    const handleCopy = () => {
        const contentToCopy = convertQuestionToStr(question); // Nội dung cần sao chép
        // const contentToCopy = question.question; // Nội dung cần sao chép
        navigator.clipboard.writeText(contentToCopy)
            .then(() => {
                setCopySuccess(true);
                message.success('Nội dung đã được sao chép!');
            })
            .catch(() => {
                setCopySuccess(false);
                message.error('Không thể sao chép nội dung!');
            });
    };

    return (
        <div
            style={{
                position: 'sticky',
                top: 0,
                backgroundColor: '#fff',
                zIndex: 10,
                padding: '10px',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <span
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '80%',
                }}
            >
                {`Câu hỏi: ${question.question}`}
            </span>
            <Button
                type="link"
                onClick={handleCopy}
                icon={<CopyOutlined />}
                style={{
                    fontWeight: 'bold',
                    color: '#1890ff',
                    borderColor: '#1890ff',
                    textTransform: 'uppercase',
                    borderRadius: '4px',
                    padding: '0 16px',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                }}
            >
                Copy nội dung
            </Button>
            {copySuccess && <span style={{ color: 'green', marginLeft: '10px' }}>Nội dung đã được sao chép!</span>}
        </div>
    );
};

export default StickyHeader;
